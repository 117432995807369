<template>
  <dx-util-popup
    ref="packageScanItemsPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="packageScanItemsPopupShown"
    @hidden="packageScanItemsPopupHidden"
  >
    <div>
      <dx-data-grid
        id="packageScanItemsGrid"
        ref="packageScanItemsGridRef"
        :data-source="items"
        :height="setHeight"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        column-resizing-mode="widget"
        :column-auto-width="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :hover-state-enabled="true"
        :row-alternation-enabled="false"
        :customize-columns="customizeColumns"
        @initialized="onInitialized"
      >
        <!--region    ::DataGird base components -->
          <dx-scrolling mode="virtual" row-rendering-mode="virtual" />
          <dx-column-chooser :enabled="true" mode="select" title="Column Chooser" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
        <!--endregion ::DataGird base components -->
        <dx-column data-field="smallImage" cell-template="imageCellTemplate" :width="100" caption="Image" alignment="center" :allow-sorting="false" />
        <template #imageCellTemplate="{data}">
          <p-lazy-img width="72px" height="72px" :src="data.value || defaultImg" />
        </template>
        <dx-column data-field="ProductTitle" :width="400" />
        <dx-column data-field="ManufactureOrSupplier" />
        <dx-column data-field="ProductGroup" />
        <dx-column data-field="NumberOfUnits" />
        <dx-column data-field="Condition" />
        <dx-column data-field="Cost" :format="{ type: 'currency', precision: 2 }" :allow-filtering="false" />
        <dx-column data-field="SalePrice" :format="{ type: 'currency', precision: 2 }" :allow-filtering="false" />
        <dx-column data-field="Fragile" />
        <dx-column data-field="MultiPack" />
        <dx-column data-field="Bundle" />
        <dx-column data-field="ASIN" />
        <dx-column data-field="MSKU" />
        <dx-column data-field="UPC" />
        <dx-column data-field="FNSKU" />
        <dx-column data-field="SerialNo" />
        <dx-column data-field="CountPerBundle" />
        <dx-column data-field="Action" />
        <dx-column data-field="receivedCount" caption="Received" :allow-filtering="false" />
        <dx-column data-field="damagedCount" caption="Damaged" :allow-filtering="false" />
        <dx-column data-field="shippedCount" caption="Shipped" :allow-filtering="false" />
        <dx-column data-field="deliveredCount" caption="Delivered" :allow-filtering="false" :allow-sorting="false" />
        <dx-column data-field="status" :allow-filtering="false" />
        <dx-column data-field="creationTime" caption="Created At" data-type="date" />
        <dx-column data-field="Order" caption="#" />
        <dx-column data-field="OrderNumber" />
        <dx-column data-field="OrderNumberSold" />
        <dx-column data-field="batchName" :show-in-column-chooser="false" />
        <dx-column data-field="sorted" caption="Sorted" :allow-filtering="false" />
        <dx-column
          data-field="amzRank"
          cell-template="rankingCellTemplate"
          width="80"
          caption="Rank"
          alignment="center"
          :allow-sorting="false"
          />
        <template #rankingCellTemplate="{data}">
          {{ data.value || '-' }}
        </template>
        <dx-column data-field="productType" width="150" caption="Category" alignment="left" :allow-sorting="false" />
        <dx-column data-field="PalletId" />
        <dx-column data-field="ShipmentDate" data-type="date" :allow-filtering="false" />
        <dx-column data-field="ExpirationDate" />
        <dx-column data-field="Notes" />
      </dx-data-grid>

    </div>
  </dx-util-popup>
</template>

<script>
import shipService from '@/http/requests/ship/shipService'
import Filters from '@robustshell/mixins/filters'
import GridBase from '@core/dev-extreme/mixins/grid/base'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
  },
  mixins: [Filters, GridBase],
  props: {
    componentId: {
      type: String,
      default: '',
    },
    companyId: {
      type: Number,
      required: true,
    },
    tracking: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
      items: [],
      images: [],
      index: 0,
      defaultImg: defaultImageUrl,
    }
  },
  computed: {
    packageScanItemsPopup() {
      return this.$refs.packageScanItemsPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        if (this.companyId && this.tracking) {
          this.getItems()
        }
        this.packageScanItemsPopup.show()
      },
    },
  },
  methods: {
    async getItems() {
      const result = await shipService.findItemsByTrackingId(this.companyId, this.tracking)
      this.items = result
    },
    packageScanItemsPopupShown() {
      this.popupTitle = `Item List #${this.tracking}`
    },
    packageScanItemsPopupHidden() {
      this.items = []
    },
    closePopup() {
      this.packageScanItemsPopup.hide()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
